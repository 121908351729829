import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import Contact from "../../components/en/Contact"
import Footer from "../../components/en/Footer"

class ContactPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Kontakt</title>
          <meta
            name="description"
            content="ĆATIĆ COMPANY DOO 31300 Prijepolje, Ivanje bb; t: +381 (0)33 771 420; t: +381 (0)33 771 564; f: +381 (0)33 743 780; e: office@catic.rs"
          />
          <meta name="image" content="https://waltz.rs/waltz_seo_image.jpg" />
          <meta itemprop="name" content="WALTZ | Kontakt" />
          <meta
            itemprop="description"
            content="ĆATIĆ COMPANY DOO 31300 Prijepolje, Ivanje bb; t: +381 (0)33 771 420; t: +381 (0)33 771 564; f: +381 (0)33 743 780; e: office@catic.rs"
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_seo_image.jpg"
          />
          <meta name="og:title" content="WALTZ | Kontakt" />
          <meta
            name="og:description"
            content="ĆATIĆ COMPANY DOO 31300 Prijepolje, Ivanje bb; t: +381 (0)33 771 420; t: +381 (0)33 771 564; f: +381 (0)33 743 780; e: office@catic.rs"
          />
          <meta
            name="og:image"
            content="https://waltz.rs/waltz_seo_image.jpg"
          />
          <meta name="og:url" content="https://waltz.rs/kontakt" />
          <meta name="og:site_name" content="WALTZ | Kontakt" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <Contact />
        <Footer />
      </Layout>
    )
  }
}
export default ContactPage
